import React from 'react'
import instagram from '../img/social/instagram.svg'
import email from '../img/social/email.svg'
import { Link } from 'gatsby'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter">
        <div className="content has-text-centered">
          <div className="container">
            <div className="columns">
              <div className="column">
                <div className="social">
                  <a title="instagram" href="https://instagram.com/colaboro.ar" target="_blank" rel="noopener noreferrer">
                    <img
                      src={instagram}
                      alt="Instagram"
                      style={{ width: '1em', height: '1em' }}
                    />
                  </a>
                  <Link to="/contacto">
                    <img
                      src={email}
                      alt="Email"
                      style={{ width: '1em', height: '1em' }}
                    />
                  </Link>
                </div>
                <p style={{ marginTop: 10 }}>San Martín 3396, Rosario, Argentina</p>
                <p><Link to="/politica-de-privacidad">Política de privacidad</Link></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
